import React, { useEffect } from "react"
import { Link,navigate } from "gatsby"
import { Helmet } from "react-helmet"
import HaveQuestion from "./new-pages/common/HaveQuestion";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "./../../static/images/speech-bubble.png";
import LastSection from "/new-pages/common/LastSection";

import { StaticImage } from "gatsby-plugin-image"
import CommonOnboardingFAQs from "./new-pages/common/CommonOnboardingFAQs"
import { FACEBOOK_PIXEL_ID } from "../config";


export default function Thankyou() {


    const [messagePopup, setMessagePopup] = React.useState(false);  
    useEffect(() => {
      let page: any = localStorage.getItem("checkoutComplete");
      if (page != "done") {
        navigate("/")
      } else {
        if (typeof window.fbq === "function") {
          window.fbq('track', 'Purchase', {currency: "$", value: 16.00});
        }
        localStorage.removeItem("checkoutComplete");
      }
    }, [])

  return (
    <>
    
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>
          Thank You 💜🐄
        </title>
          <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init',${FACEBOOK_PIXEL_ID}); 
        `}</script>
          <noscript>{`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"/>
        `}</noscript>
      </Helmet>
      <section className="thankyou-sec text-center py-20 body--lato">
        <div className="">
        <Link to="/">
              <StaticImage
                  className="w-28 mb-5"
                  layout="constrained"
                  src="../content/cow.png"
                  alt="Purple Cow Internet 💜🐄"
              />
          </Link>
         
    
          <section className="tv-section1">
            <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Golly, that was easy!</h2>
                </div>
            <div>
                <iframe width="1120" height="630" src="https://www.youtube.com/embed/RJb3aDMrScs?si=h41yF7-oRokrQEjr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </section>
            <h2 className="tv-section1 max-width1000">
            Here are some next step:
          </h2>
            <section className="tv-section1 max-width1000">
                <h2>1: You should be receiving a text from us any minute to confirm some details.</h2>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>2: We will ship the modem to you so it arrives on your requested install date.</h2>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>3: When you receive our package in the mail follow the included setup instructions and text us "Plugged in". </h2>
            </section>
          <CommonOnboardingFAQs />
          <HaveQuestion closepopup={setMessagePopup}/>
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </div>
      </section>
    </>
  )
}

